import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { getAssetUrl } from '../services/cdn'
import { Container as BlockContainer } from '../components/Blocks'
import { HeroVideo, CoreValues } from '../components/Sections'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'

const Block = styled.div`
  height: 55vh;
  @media screen and (min-width: 1800px) {
    height: 80vh;
  }
  @media screen and (min-width: 2600px) {
    height: 100vh;
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Culture" keywords={[`gatsby`, `application`, `react`]} />
    <HeroVideo id="main-hero" height={null} x={50} y={50} src="app/Video/anniversary.mp4" posterSrc={getAssetUrl('app/video-poster.png')} loop={true} autoplay muted>
    {/* <HeroVideo id="main-hero" height={null} x={50} y={50} src="app/Video/" posterSrc={getAssetUrl('app/ASEAN_Banner.jpg')} loop={true} autoplay muted> */}
      <Block />
    </HeroVideo>
    <BlockContainer padding={0} className="pt-5">
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">SIDC CULTURE</h1>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <Container fluid>
      <Row>
        <Col md={{ size: 6, order: 1}} sm={{ size: 12, order: 2}} className="p-0">
          <div className="px-md-5 p-5">
            <h1 className="text-center">Our Quality Policy</h1>
            <p className="mb-3 lead"><b>S</b> atisfaction of our members and customers.</p>
            <p className="mb-3 lead"><b>I</b> ntegrity in everything that we do.</p>
            <p className="mb-3 lead"><b>D</b> evelopment and engagement of our people.</p>
            <p className="mb-3 lead"><b>C</b> ommitment to continually improve our processes and Quality Management System.</p>
          </div>
        </Col>
        <Col md={{ size: 6, order: 2}} sm={{ size: 12, order: 1}}
          style={{
            backgroundImage: `url("${getAssetUrl('app/SIDC_Culture_Cover_Photo.jpg')}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'right center'
          }}
        >
          <div style={{ height: '350px' }} />
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 6, order: 2}} sm={{ size: 12, order: 2}} className="p-0">
          <div className="px-md-5 p-5">
            <h1>SIDC's Aspire to Culture</h1>
            <p className="mb-3 lead">To support our company's future challenges and growth, everybody should be:</p>
            <ul className="lead">
              <li>Customer-focused</li>
              <li>Open and Transparent</li>
              <li>With Entrepreneurial Mindset</li>
              <li>Focused and Fast Executing</li>
              <li>With Institutional Loyalty</li>
              <li>Facts-based</li>
              <li>Practice Responsibility with Accountability</li>
            </ul>
            <h2>Let's solve for SIDC!</h2>
          </div>
        </Col>
        <Col md={{ size: 6, order: 1}} sm={{ size: 12, order: 1}} className="p-0"
          style={{
            backgroundImage: `url("${getAssetUrl('app/Culture.png')}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover, cover',
            backgroundPosition: 'left 20%'
          }}
        >
          <div style={{ height: '350px' }} />
        </Col>
      </Row>
    </Container>
    <CoreValues />
    {/* <BlockContainer padding={5} dark id="awards">
      <Container>
          <Row>
          <Col md="12">
              <div className="text-center">
                <h2 className="pb-4 text-dark">Awards</h2>
              </div>
          </Col>
          </Row>
          <Row>
          <Col sm={{order: 2}} md={{ size: 3, order: 1}} className="align-self-center text-md-right text-center">
              <h4 className="text-primary">One</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Two</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Three</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
          </Col>
          <Col sm={{ order: 1 }} md={{ size: 6, order: 2}} className="my-3 d-none d-md-block"> 
              <img className="img-fluid d-block pt-5" alt="SIDC BENEFITS" src={getAssetUrl('app/Awards.png')} />
          </Col>
          <Col sm={{order: 3}} md={{ size: 3, order: 3}} className="align-self-center text-md-left text-center">
              <h4 className="text-primary">One</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Two</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Three</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
          </Col>
          </Row>
      </Container>
    </BlockContainer> */}
    {/* <Parallax src="app/parallax.jpg">
      <Row>
        <Col sm="6" className="text-sm-left">
          <h1 className="text-primary">
              <b>Read the <br /> Chairman's Message</b>
          </h1>
        </Col>
        <Col sm="6" className="text-sm-right d-flex align-items-end flex-column">
          <Link to="/chairperson-message" className="btn btn-primary mt-auto mb-auto"><i className="fa fa-download fa-fw"></i> Read Chairman's Message</Link>
        </Col>
      </Row>
    </Parallax> */}
  </Layout>
)

export default IndexPage
